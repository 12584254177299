.Highlight {
  position: absolute;
}

.Highlight__emoji {
  position: absolute;
  color: black;
  opacity: 1;
  font-size: 28px;
}

.Highlight__parts {
  opacity: 1;
}

.HighlightCandidate__part {
  cursor: pointer;
  position: absolute;
  background: #3E8AFF;
  opacity: 0.3;
  transition: background 0.3s;
}

.HighlightCandidateLP__part {
  cursor: pointer;
  position: absolute;
  background: #f40000;
  opacity: 0.3;
  transition: background 0.3s;
}

.HighlightLinesReprocessing__part {
  cursor: pointer;
  position: absolute;
  background: transparent;
  border: 1px solid #f40000;
  opacity: 0.6;
  transition: background 0.3s;
}

.HighlightWord__part {
  cursor: pointer;
  position: absolute;
  background: transparent;
  opacity: 0.6;
  transition: background 0.3s;
}

.HighlightWord__part:hover {
  border: 2px dotted #3E8AFF;
}

.Highlight__popup {
  background-color: #3d464d;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  max-width: 300px;
  max-height: 100px;
  overflow-y: scroll;
}

.Highlight--scrolledTo .HighlightCandidate__part {
  background: #ff4141;
}
