.Tip__compact {
  cursor: pointer;
  background-color: #3d464d;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
}

.Tip__card {
  padding: 10px;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0px 4px 8px 1px #4C4E646B;
  display: flex;
  flex-direction: column;
}

.Tip__card p {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #4C4E64DE;
  margin-block-start: 10px;
  margin-block-end: 10px;
}

.Tip__card textarea {
  width: 180px;
  height: 40px;
  border-radius: 4px;
  background: #FFFFFF;
  border: 2px solid #3E8AFF;
  padding: 8px;
  font-size: 14px;
  color: #4C4E64;
  font-weight: 500;
}

.Tip__card textarea:focus {
  border: 2px solid #3E8AFF;
  border-color: #3E8AFF;
}

.Tip__card textarea:focus-visible {
  outline: none;
}

.Tip__submit {
  margin-top: 10px;
  margin-right: 8px;
  background: #3E8AFF;
  color: #FFFFFF !important;
  box-shadow: 0px 4px 8px -4px #4C4E646B;
  font-size: 13px !important;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  border: none;
  border-radius: 4px;
  height: 30px;
  padding: 0 12px;
  cursor: pointer;
}

.Tip__cancel {
  margin-top: 10px;
  background: #FFFFFF;
  color: #3E8AFF !important;
  font-size: 13px !important;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  border: none;
  border-radius: 4px;
  height: 30px;
  padding: 0 12px;
  cursor: pointer;
}
