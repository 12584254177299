/* overrides for pdf_viewer.css from PDF.JS web viewer */

.textLayer {
  z-index: 2;
  opacity: 1;
  mix-blend-mode: multiply;
  display: flex;
}

.annotationLayer {
  position: absolute;
  top: 0px;

  z-index: 3;
}

.textLayer .highlight.selected {
  opacity: 1;
  background-color: #ef2648;
}

.textLayer .highlight {
  opacity: 1;
  border-radius: 0px;
  background-color: #5a8a91;
}

html
  body
  .textLayer
  > div:not(.PdfHighlighter__highlight-layer):not(.Highlight):not(.Highlight-emoji) {
  opacity: 1;
  mix-blend-mode: multiply;
}

.textLayer ::selection {
  opacity: 1;
  background: #fbc800;
  mix-blend-mode: multiply;
}

.pdfViewer.removePageBorders .page {
  margin: 1px 0px 6px 0px;
  border: none;
  display: flex;
  justify-content: center;
  width: 100% !important;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .textLayer {opacity: 0.5;}
}

/* Internet Explorer support method */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .textLayer {opacity: 0.5 }
}

/* Microsoft Edge Browser 12+ (All) - @supports method */
@supports (-ms-ime-align:auto) {
  .textLayer {opacity: 0.5 }
}
